﻿$secondaryPrimaryColor: #652239 !default;
$secondaryMainLinkColor: transparent; //darken($secondaryPrimaryColor, 10%) !default;
$secondaryAlternateLinkColor: darken($secondaryPrimaryColor, 20%) !default;

.secondary {
    background-color: $secondaryPrimaryColor;
    transition: all .45s;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    padding-top: 0;

    &.position-left,
    &.position-right {
        width: 100%;
    }

    &.position-left {
        transform: translateX(-100%);
    }

    &.position-right {
        transform: translateX(100%);
    }

    &.is-open {
        transform: translate(0, 0);
    }

    .top-links {
        //padding-top: 1rem;
    }

    .loading-indicator {
        span {
            top: 50%;
            margin-top: -.5em;
        }
    }

    .search-box {
        display: flex;
        position: relative;
        width: 100%;
        padding: 0 2rem;
        margin-top: 20px;

        .container {
            height: 40px;
            width: 100%;
            background: $white;
            border-radius: 30px;
            z-index: 2;
            position: relative;
            display: flex;

            button {
                color: rgb(204, 204, 204);
                padding: 0 1.5rem;
                outline: none;
                cursor: pointer;
            }

            input {
                border-bottom: none !important;
                width: 85%;
                position: absolute;
                right: 5px;
                box-shadow: none;
                border: none;
                border-radius: 30px;

                &:focus {
                    border-radius: 30px;
                }
            }
        }
    }

    > .nav-container {
        flex: 0 0 100%;
    }

    a {
        color: $white;

        &:hover, &:active, &:focus {
            background-color: transparent;
        }
    }

    .quick-links {
        display: flex;
        align-items: stretch;

        a {
            display: flex;
            flex-flow: column nowrap;
            width: 130px;
            margin-left: 0 !important;
            text-align: center;
            padding-top: 10px;
            color: $white;
            font-size: rem-calc(12);
            text-transform: uppercase;
            flex-grow: 1;
            align-items: center;

            &:first-child {
                background: linear-gradient(144.84deg, #95DDD9 0%, #5CB8B2 100%);
            }

            &:nth-child(2) {
                background: linear-gradient(143.47deg, #F6D500 0%, #EAAA00 100%);
            }

            &:nth-child(3) {
                background: linear-gradient(148.71deg, #DCE125 0%, #B7BF10 100%);
            }

            img {
                margin-bottom: 5px;
                height: 17px;
                width: 18px;
            }

            span {
                letter-spacing: 2px;
            }
        }
    }

    a {
        outline: none;

        i {
            font-size: rem-calc(22);
        }
    }

    .nav {
        overflow-x: hidden;
        flex: 1 1 auto;
        margin-top: 40px;
        position: relative;

        > div {
            margin-bottom: 30px;
        }

        .nav-title {

            span {
                background-color: $secondaryPrimaryColor;
                padding: 0 10px;
                /*&:after {
                    content: "";
                    position: absolute;
                    border-top: 1px solid white;
                    top: 13px;
                    width: 50%;
                    margin-left: 15px;
                }*/
            }
        }

        ul {
            width: 100%;

            li {
                border-bottom-width: 0;
                display: flex;
                flex: 1;
                width: 100%;
            }

            a {
                padding: .5em 1.5em;
                flex: 0 0 100%;
                position: relative;

                &.with-submenu {
                    i {
                        position: absolute;
                        right: 10%;
                        top: 8px;
                    }
                }
            }
        }
        /*span {
            flex: 1 1 auto;
            display: block;

            &:last-child {
                flex: 0 0 auto;
            }
        }*/
    }

    .back-link {
        margin-top: 1em;

        a {
            flex: 0 0 100%;
            display: flex;
            padding: .5em 1em .5em 2em;

            i {
                margin-right: 10px;
                position: relative;
                top: -2px;
                transform: rotate(180deg);
            }
        }
    }

    .multi-nav {
        > li {
            //background-color: $secondaryAlternateLinkColor;

            &.level-1 {
                //background-color: $secondaryAlternateLinkColor;
            }

            &:not(.level-1) {
                a {
                    padding: .5em 1em .5em 2em;
                }

                .standard {
                    a {
                        padding: .5em 1em;
                    }
                }
            }

            &:not(:first-child) {
                a {
                    //background-color: $secondaryAlternateLinkColor;
                }

                li + li {
                    &:not(.standard)::before {
                        content: '';
                        display: block;
                        height: 1px;
                        //background-color: $secondaryMainLinkColor;
                    }
                }

                .back, .current {
                    //background-color: $secondaryMainLinkColor;

                    &::before {
                        //background-color: $red;
                    }
                }
            }

            a {
                &:hover {
                    background-color: #852C4B;
                }
            }
        }
    }

    .level-1 {
        a {
            padding: .5em 1em;
        }

        li {
            &:last-child {
                border-bottom-width: 0;
            }
        }
    }

    .standard {
        background-color: $secondaryMainLinkColor;

        a {
            background-color: $secondaryMainLinkColor;
        }

        + .standard {
            &::before {
                content: '';
                display: block;
                margin: 0 1em;
                height: 1px;
                background-color: $secondaryAlternateLinkColor;
            }
        }
    }

    .extra-link {
        a {
            //background-color: $secondaryAlternateLinkColor;
        }

        + .extra-link {
            &::before {
                content: '';
                display: block;
                margin: 0 1em;
                height: 1px;
                //background-color: $secondaryMainLinkColor;
            }
        }
    }

    .current {
        text-transform: uppercase;
    }

    .fa-angle-right {
        position: relative;
        top: 2px;

        &.fa-rotate-180 {
            top: -2px;
            margin-right: .5em;
        }
    }

    @include breakpoint(medium) {
    }

    @media screen and (min-width: 40em) {
        &.position-left {
            width: 500px;
            //transform: translateX(-500px);
        }
    }

    @include breakpoint(large) {
        transform: none;
    }
}

.submenus {
    display: none;

    ul {
        margin-left: 0;
    }
}
