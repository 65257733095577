﻿.widget-trumba-events-feed {
    &.variant-upcoming-events {
        .upcoming-events {
            flex-flow: column nowrap;
            z-index: 3;
            position: relative;

            .loader-wrap {
                min-height: 600px;
            }

            p {
                color: $night-rider;
                font-size: rem-calc(23);
                font-weight: $light;
                letter-spacing: 4px;
                text-transform: uppercase;
                margin-bottom: 25px;
                width: 100%;
            }


            .upcoming-event-lists {
                .upcoming-event-item {
                    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
                    background-color: $white;
                    padding: 18px 18px 0 18px;
                    border-radius: 5px;
                    height: 169px;
                    width: 293px;
                    margin-top: 25px;

                    > a {
                        outline: none;

                        > div {
                            display: flex;
                            flex-flow: row nowrap;
                            height: 135px;

                            > * {
                                flex: 1 100%;
                            }
                        }
                    }

                    p {
                        margin: 0;
                    }

                    .month-day {
                        flex: 0 0 auto;
                        width: 96px;
                        height: 103px;
                        background: $light-sea-green;
                        border-radius: 4px;
                        font-size: rem-calc(97);
                        color: $white;
                        text-align: center;
                        margin-right: 10px;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;
                        justify-content: center;


                        .month {
                            font-size: rem-calc(20);
                            line-height: $line-height;
                            font-weight: $semibold;
                            color: $half-baked;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                        }

                        .day {
                            font-size: rem-calc(40);
                            line-height: $line-height;
                            color: $white;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                        }
                    }

                    .desc {
                        padding-left: 0;
                        color: $black;
                        display: flex;
                        flex-flow: row wrap;

                        p {
                            font-size: rem-calc(12);
                            letter-spacing: 2px;
                        }

                        p.event-title {
                            font-size: rem-calc(18);
                            line-height: rem-calc(23);
                            color: $night-rider;
                            font-weight: $regular;
                            letter-spacing: 1.2;
                            align-self: flex-start;
                            text-align: left;
                            word-break: break-word;
                        }

                        > div {
                            align-self: flex-end;
                            width: 100%;
                            text-transform: uppercase;
                        }

                        span {
                            opacity: 0.38;

                            &.venue {
                                opacity: 1;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .slider-nav {
                font-size: rem-calc(70);
                color: $white;
                position: absolute;
                top: 170px;

                :hover {
                    //color: $cannon-pink;
                }

                &-left {
                    left: -6%;
                    text-shadow: -1px 2px 2px rgba(0,0,0,0.22);
                }

                &-right {
                    right: -6%;
                    text-shadow: 2px 2px 2px rgba(0,0,0,0.22);
                }
            }
        }
    }
}
