﻿.widget-feed {
    &.variant-latest-news {
        .news-item {
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 55px;
            width: 538px;
            min-height: 251px;

            > div {
                padding: 20px;
                display: flex;
                flex-flow: column nowrap;
                flex: 60%;

                &.image-container {
                    flex: 40%;
                    height: 251px;
                    background-size: cover;
                    background-position: center;
                }
            }
        }

        img {
            width: 205px;
        }

        .category {
            font-size: rem-calc(12);
            letter-spacing: 3px;
            text-transform: uppercase;
            color: $black;
            margin-bottom: 0;
            flex-grow: 0;
        }

        h3 {
            @include font-calluna-regular;
            color: $deep-pink;
            font-size: rem-calc(34);
            line-height: 1;
            flex-grow: 1;
            margin-top: 10px;
        }

        .date {
            font-weight: $semibold;
            font-size: rem-calc(12);
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $matterhorn;
            margin-bottom: 0;
            justify-self: flex-end;
        }

        &.large-end {
            .news-item {
                margin-bottom: 0;
            }
        }
    }

    &.variant-merchandise {
        .merchandise-product {
            width: 100%;
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            margin-bottom: 20px;

            .product-image {
                height: 305px;
                background: radial-gradient(circle, #FFFFFF 0%, #E5E5E5 100%);
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
            }

            .product-info {
                padding: 25px 15px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                height: 120px;

                .name {
                    width: 70%;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                }

                .price {
                    width: 25%;
                    text-align: right;
                    font-size: rem-calc(23);
                }
            }
        }
    }

    &.variant-news {
        .news-item {
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            display: flex;
            flex: 1 1 0;
            flex-flow: column nowrap;
            margin-bottom: 55px;
            width: 100%;
            height: 430px;
            padding: 0 0.5rem;

            > div {
                padding: 20px;
                display: flex;
                flex-flow: column nowrap;
                width: auto;
                margin-top: 0;
                height: 50%;

                &.image-container {
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }

        img {
            width: auto;
        }

        .text {
            height: 100%;
        }

        .category {
            font-size: rem-calc(12);
            letter-spacing: 3px;
            text-transform: uppercase;
            color: $black;
            margin-bottom: 0;
            flex-grow: 0;
        }

        h3 {
            @include font-calluna-regular;
            color: $deep-pink;
            font-size: rem-calc(34);
            line-height: 1;
            flex-grow: 1;
            margin-top: 10px;
        }

        .date {
            font-weight: $semibold;
            font-size: rem-calc(12);
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $matterhorn;
            margin-bottom: 0;
            justify-self: flex-end;
        }

        &.large-end {
            .news-item {
                margin-bottom: 0;
            }
        }
    }
}
