﻿input {
    &:autofill,
    &:-webkit-autofill, 
    &:-internal-autofill-selected {
        background-color: transparent;
    }
}

.field-validation-error {
    color: $red;
    font-size: rem-calc(14);
}

.validation-summary-errors {
    color: $red;

    ul {
        list-style: none;
        margin-left: 0;
    }
}

.submitting {
    button[type="submit"] {
        background-color: $gray;
    }
}

/* MATERIAL DESIGN CONTROLS */
.mdc-form-field {
    font-family: $body-font-family;

    fieldset {
        width: 100%;
    }
}

.mdc-floating-label,
.mdc-select__selected-text {
    font-family: $body-font-family;
}

.mdc-text-field {
    width: 100%;
    padding: 0;

    input[type=text],
    input[type=password],
    input[type=email],
    input[type=number],
    textarea {
        border: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0.5rem 0;
    }

    .mdc-floating-label {
        top: 70%;
        left: 0;
        /*left: 0;
        bottom: 0;
        top: 50%;
        transform: none;
        color: rgba(0,0,0,.26);*/
    }

    .mdc-floating-label--float-above {
        top: 50%;
    }

    &:hover {
        background: none;
    }
}

.mdc-text-field__ripple,
.mdc-select__ripple {
    &::before, &::after {
        background-color: transparent !important;
    }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
    background-color: transparent;

    .mdc-line-ripple {
        &::before {
            border-bottom-color: $nero;
        }

        &::after {
            border-bottom-color: $deep-pink;
            border-bottom-width: 2px;
        }
    }
    /*.mdc-floating-label {
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
}*/
}

.mdc-text-field--focused:not(.mdc-text-field--disabled),
.mdc-select:not(.mdc-select--disabled).mdc-select--focused {
    background-color: transparent;

    .mdc-floating-label {
        color: $deep-pink;

    }
}

.mdc-text-field__ripple {
    &::after {
        background-color: transparent;
    }
}

.mdc-text-field--textarea {
    .mdc-text-field__input {
        padding: 0;
    }

    .mdc-floating-label {
        top: 40%;
        text-wrap: wrap;

        &.mdc-floating-label--float-above {
            top: 20% !important;
            text-wrap: nowrap;
        }
    }
}

.mdc-checkbox {
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
        background-color: $deep-pink;
        border-color: $deep-pink;
    }
}

.mdc-select {
    width: 100%;

    .mdc-select__anchor {
        padding: 0;
        width: 300px;
    }
}

.mdc-select--filled .mdc-floating-label {
    left: 0;
}

.mdc-deprecated-list-item {
    height: auto;
}

.mdc-menu-surface {
    max-height: 200px!important;
}

/* MUI CONTROLS */
.MuiTextField-root {
    .MuiInput-root {
        input[type=text],
        input[type=password] {
            border: none;
            box-shadow: none;
            background-color: transparent;
        }

        &::after {
            border-bottom: 2px solid $deep-pink;
        }
    }

    .MuiFormLabel-root {
        &.Mui-focused {
            color: $deep-pink;
        }
    }
}

.MuiFormControlLabel-label {
    font-family: $font-site !important;
    font-size: 1rem;
    color: #4C4C4C;
}

/* UMBRACO FORMS - MATERIAL */
.umbraco-forms-material {
    .umbraco-forms-caption {
        font-size: 2rem;
        color: $deep-pink;
        margin-bottom: 10px;
        text-align: center;
    }

    .fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }

    .umbraco-forms-navigation {
        padding-top: 50px;

        > div {
            text-align: right;
        }

        button {
            min-width: 275px;
        }
    }

    .fileupload {
        padding-top: 40px;
    }

    .titleanddescription {
        margin-top: 20px;

        h2 {
            font-size: .75rem;
            color: rgba(0,0,0,.54);
            font-weight: 400;
            margin-bottom: 10px;
            text-align: left;
        }

        p {
            color: $nero;
            font-size: 1rem;
        }
    }
}
