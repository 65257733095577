﻿.social-media {
    display: flex;
    align-items: center;

    a {
        height: 41px;
        width: 41px;
        border: 1px solid #fff;
        border-radius: 41px;
        display: inline-block;
        margin-left: 55px;

        &:first-child {
            margin-left: 0;
        }
    }

    &.footer-section {
        margin-top: 25px;

        > div {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            a {
                height: 41px;
                width: 41px;
                border: 1px solid #fff;
                border-radius: 41px;
                display: flex;
                color: #fefefe;
                justify-content: center;
                align-items: center;
                font-size: 1.125rem;
                margin-left: 0;
            }
        }
    }

    @include breakpoint(large) {
        a {
            height: 26px;
            width: 26px;
            font-size: 1.25rem;
            border: none;
            margin-left: 5px;
            color: $white;
        }

        &.footer-section {
            margin-top: 0;

            > div {
                justify-content: flex-end;

                a {
                    border: none;
                    height: auto;
                    width: auto;
                    font-size: 1.25rem;
                    margin-left: 15px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
