﻿.footer {
    background-color: $deep-pink;
    position: relative;
    z-index: 2;
    padding: 50px 0;
}

.footer-container {    
    display: flex;
    flex-flow: column nowrap;

    .nav-title {
        margin:10px 0 20px;
        width:95%; 

        span {
            background-color: $deep-pink;
            padding: 0 10px 0 0;
        }
    }

    .footer-1st-col {
        flex: 0 0 auto;
        text-align: center;

        img {
            width: 109px;
        }
    }

    .footer-2nd-col {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column nowrap;
        margin-top: 50px;
        
        
        .vertical-separator {
            height: 75px;
            width: 3px;
            border-left: 1px solid $white;
            margin-right: 50px;
            margin-top: 15px;
        }

        > .col {
            display: flex;
            flex-flow: column nowrap;
            margin-right: 35px;
            width: 100%;

            img {
                align-self: flex-start;
                margin-right: 24px;
                margin-top: 10px;
            }

            >div {
                p {
                    color: $white-smoke;
                    font-size: rem-calc(16);
                    line-height: 1.125;

                    a {
                        color: $white-smoke;
                    }
                }

                .label {
                    background-color: transparent;
                    color: $white;
                    font-size: rem-calc(12);
                    font-weight: $light;
                    text-transform: uppercase;
                    margin: 0;
                    padding: 0;
                    letter-spacing: 2px;
                    opacity: 0.65;
                    margin-bottom: 6px;
                }
            }
        }

    }

    .footer-3rd-col {
        flex: 1 1 auto;
        text-align: left;
        margin-top: 20px;

        .nav-title {
            span {
                &:after {
                    width: 80%;
                }
            }
        }
    }

    .footer-contacts {
        a {
            color: #fff;
            padding: 5px 0;
        }

        span {
            color: #fff;
            padding: 5px 0;
        }
    }

    /*@include breakpoint(x-large) {
        width: $container-width;  
        margin: 0 auto;
    } */  

    
}

.design-developed {
    text-align: center;
    font-size: 14px;
    color: $white;
    margin-top: 20px;

    a {
        color: $white;
    }

}

@include breakpoint(large) {
    .footer-container {
        flex-flow: row nowrap;

        .footer-2nd-col {
            margin-left: 40px;
            margin-top: 0;
            flex-flow: row nowrap;

            > .col {
                flex-flow: row nowrap;
                width: auto;
            }
        }

        .footer-3rd-col {
            text-align: right;
            margin-top: 0;
            display: flex;
            flex-flow: column nowrap;
        }
    }
}

.footer-menu {
    ul {
        list-style: none;
        margin-left: 0;

        li {
            display: block;
            //padding-left: 25px;
            padding: 5px 0;

            a {
                @include menu-links;
                font-size: rem-calc(14);
            }
        }
    }

    @include breakpoint(large) {
        ul {
            li {
                display: inline-block;
                padding-left: 25px;
            }
        }
    }
}
