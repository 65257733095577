﻿.widget-image-links {
    &.variant-default {
        @include breakpoint(medium) {
            a {
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
