﻿.widget-news-listing {
    &.variant-default {
        .filters {
            background: $whisper;
            padding: 10px 20px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            font-size: rem-calc(13);
            margin-bottom: 20px;

            p {
                margin: 0;
                flex: 1;
                color: #333;
                font-style: italic;
            }

            a {
                justify-self: end;
                font-style: italic;
                color: #333;
                text-decoration: underline;

                &:before {
                    content: '\00d7';
                    font-style: normal;
                    font-size: rem-calc(11);
                }
            }
        }

        .categories, .news-years {
            ul {
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                padding: 20px;
                margin-left: 0;
                margin-top: 10px;

                li {
                    list-style: none;
                }
            }

            @include breakpoint(large) {
                padding-right: 2rem;
            }
        }

        .news-item-wrapper {
            @include breakpoint(medium) {
                padding-left: 0;

                &:nth-child(odd) {
                    padding-right: 1rem;
                }

                &:nth-child(even) {
                    padding-left: 1rem;
                }
            }

            @include breakpoint(large only) {
                width: 100%;
            }
        }

        .news-item {
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            display: flex;
            flex-flow: column nowrap;
            margin-bottom: 55px;
            //width: 100%;
            min-height: 250px;

            > div.image-container {
                width: 100%;
                height: 250px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }

            > div.text {
                padding: 20px;
                display: flex;
                flex-flow: column nowrap;
                flex: 0 1 60%;
            }

            @include breakpoint(large) {
                flex-flow: row nowrap;

                > div.image-container {
                    width: 205px;
                    flex: 0 1 40%;
                }
            }
        }

        img {
            width: 205px;
        }

        .category {
            font-size: rem-calc(12);
            letter-spacing: 3px;
            text-transform: uppercase;
            color: $black;
            margin-bottom: 0;
            flex-grow: 0;
        }

        h3 {
            @include font-calluna-regular;
            color: $deep-pink;
            font-size: rem-calc(30);
            line-height: 1;
            flex-grow: 1;
            margin-top: 10px;
            height: 120px;

            @include breakpoint(large) {
                font-size: rem-calc(34);
            }
        }

        .date {
            font-weight: $semibold;
            font-size: rem-calc(12);
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $matterhorn;
            margin-bottom: 0;
            justify-self: flex-end;
        }

        &.large-end {
            .news-item {
                margin-bottom: 0;
            }
        }
    }
}
