﻿.accordion {
    background: transparent;
    border: none;
    border-top: 1px solid #83786F;
    margin-left: 0;
    margin-right: 0;
}

.accordion-title {
    background: transparent;
    color: #1e1e1e;
    text-transform: uppercase;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border: none;

    &::before {
        right: 0.5rem;
        font-size: 1.75rem;
        width: 1rem;
        line-height: 1rem;
        text-align: center;
        transform: rotateZ(0);
        color: #1e1e1e;
        transition: color .25s,transform .25s;
        transform-origin: center;
    }

    &:hover, &:focus, &:active {
        background: transparent;
        outline: none;
    }

    :last-child:not(.is-active) > & {
        border-bottom: none;
    }
}

.is-active {
    > .accordion-title {
        outline: none;
        background: transparent;
        color: #1e1e1e;

        &::before {
            content: "+";
            transform: rotateZ(-45deg);
            color: #f71634;
        }
    }
}

.accordion-item {
    border-bottom: 1px solid #83786F;

    a {
        color: inherit;
    }


    &:not(.is-active) {
        &:hover {
            background: #f0f1f5;

            a {
                color: inherit;

                &:active, &:focus {
                    color: inherit;
                }
            }
        }
    }
}

.accordion-content {
    background: transparent;
    border: none;
    padding-right: 2rem;
    padding-left: 2rem;

    a {
        color: $linkColor;
    }
}

@include breakpoint(large) {
    .accordion-title {
        &:before {
            right: 1rem;
        }
    }
}
