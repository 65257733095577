﻿@import 'foundation-sites/scss/foundation.scss';
@include foundation-global-styles;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-accordion;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-responsive-embed;
@include foundation-reveal;
@include foundation-table;
@include foundation-tabs;
@include foundation-visibility-classes;
@include foundation-xy-grid-classes;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-dropdown-menu;
@include foundation-callout;
@include foundation-off-canvas;


@include breakpoint(xlarge) {
    .hide-for-large-only {
        display: none!important;
    }
}