﻿.widget-image-text-list {
    &.variant-default {
        .imagetext-contents {
            .imagetext-content {
                display: flex;
                margin-top: 30px;
                flex-flow: column nowrap;
                margin-left: 0.5rem;
                margin-right: 0.5rem;

                .cell {
                    display: flex;

                    &:first-child {
                        flex-flow: column nowrap;
                    }

                    &:nth-child(2) {
                        flex-flow: column nowrap;
                        justify-content: center;
                    }
                }

                .image-content {
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 4px;
                    flex: 0 0 auto;
                    box-shadow: 2px 2px 22px 0 rgba(0,0,0,0.23);
                    width: 280px;
                    height: 280px;
                    margin: 0 auto;
                }

                .text-content {
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    text-align: center;
                    margin-top: 20px;
                }

                .bordered-content {
                    height: 50px;
                    border-left: 6px solid #9CDBD9;
                    margin: 20px auto 10px;
                }

                @include breakpoint(medium) {
                    flex-flow: row nowrap;
                    margin-left: unset;
                    margin-right: unset;

                    .cell {
                        &:first-child {
                            flex-flow: row nowrap;
                        }
                    }

                    .text-content {
                        width: 45%;
                        text-align: unset;
                        margin-top: unset;
                    }

                    .image-content {
                        width: 310px;
                        height: 310px;
                        margin: unset;
                    }

                    &:nth-child(odd) {
                        .text-content {
                            margin-left: 45px;
                        }
                    }

                    &:nth-child(even) {

                        .image-content {
                            order: 2;
                        }

                        .text-content {
                            margin-right: 45px;
                            text-align: right;
                            order: 1;
                        }
                    }
                }

                @include breakpoint(large) {
                    position: relative;
                    margin-top: unset;

                    .bordered-content {
                        border: 0;
                        border-top: 6px solid #9CDBD9;
                        width: 270px;
                        height: 95px;
                        position: relative;
                        margin: unset;
                    }

                    &:nth-child(odd) {
                        .bordered-content {
                            border-right: 6px solid #9CDBD9;
                        }
                    }

                    &:nth-child(even) {
                        .cell {
                            &:first-child {
                                order: 2;
                            }

                            &:last-child {
                                order: 1;
                            }
                        }

                        .bordered-content {
                            border-left: 6px solid #9CDBD9;
                        }
                    }
                }
            }
        }
    }
}
