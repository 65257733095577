﻿@mixin bannerText() {
    h2 {
        @include font-calluna-regular;
        font-size: 3rem;
        color: $white;
        text-align: center;
        margin: 0;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.27);
    }

    h3 {
        @include font-calluna-regular;
        font-size: rem-calc(48);
        color: $white-smoke;
        text-align: center;
        margin: 0;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.27);
    }

    h1, h4 {
        color: $white-smoke;
        margin: 0;
        font-size: rem-calc(23);
        line-height: 3rem;
        text-transform: uppercase;
        font-weight: $light;
        letter-spacing: 5px;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.27);
    }

    h1 {
        margin-bottom: 0 !important;
    }

    blockquote {
        @include font-calluna-regular;
        font-size: rem-calc(32);
        color: $white-smoke;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.27);
    }

    p {
        text-shadow: 2px 2px 2px rgba(0,0,0,0.27);
    }
}

@mixin wrapper() {
    position: relative;
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff;
    text-align: center;
    overflow: hidden;
    background-color: $deep-pink;
}

.widget-banner {
    .grid-container {
        width: 100%;
    }

    &.variant-basic {
        .wrapper {
            min-height: 420px;
            @include wrapper();
        }

        .rte {
            width: 95%;
            @include bannerText;

            p {
                color: $white-smoke;

                &.large-text-calluna {
                    font-size: 1.3125rem;
                }
            }
        }

        @include breakpoint(large) {
            margin-bottom: 0;
            padding: 0;

            .wrapper {
                min-height: 600px;
            }

            .rte {
                width: 75%;
                margin: 0 auto;

                h2 {
                    font-size: 6.625rem;
                }

                p {
                    &.large-text-calluna {
                        font-size: 1.4375rem;
                    }
                }
            }
        }

        @include breakpoint(xlarge) {
            .rte {
                width: 50%;
            }
        }
    }

    &.variant-small {
        height: auto !important;
        margin-bottom: 0;
        padding: 0;
        position: relative;

        .wrapper {
            min-height: 420px;
            @include wrapper();
        }

        .rte {
            width: 95%;
            margin: 0 auto;
            padding: rem-calc(125) 1rem;

            @include bannerText;

            p {
                color: $white-smoke;

                &.large-text-calluna {
                    font-size: 1.3125rem;
                }
            }
        }

        @include breakpoint(large) {
            .wrapper {
                min-height: 500px;
            }

            .rte {
                width: 75%;
                margin: 0 auto;

                h2 {
                    font-size: 6.625rem;
                }

                p {
                    &.large-text-calluna {
                        font-size: 1.4375rem;
                    }
                }
            }
        }

        @include breakpoint(xlarge) {
            .rte {
                width: 50%;
            }
        }
    }

    &.variant-with-content-header {
        height: auto !important;
        margin-bottom: 0;
        position: relative;

        .wrapper {
            min-height: 420px;
            @include wrapper();

            &.no-image {
                min-height: 0;
            }
        }

        .rte {
            width: 95%;
            margin: 0 auto;

            @include bannerText;

            p {
                color: $white-smoke;
            }
        }

        .message-section {
            position: relative;
            min-height: 200px;
            text-align: center;
            padding: 0 1rem 50px;

            $iconHeadDimension: 130px;

            .icon-head {
                height: $iconHeadDimension / 2;

                > div {
                    width: $iconHeadDimension;
                    height: $iconHeadDimension;
                    background-color: $white;
                    border-radius: 100px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -($iconHeadDimension / 2);
                    z-index: 2;

                    img {
                        border-radius: 75px;
                        width: 110px;
                        height: 110px;
                    }
                }
            }

            h3.no-image {
                margin-top: 70px;
            }

            .small-text, .small-text p, p {
                font-size: rem-calc(12);
                text-transform: uppercase;
                color: $black;
                letter-spacing: 2px;
                font-weight: 300;
            }

            .text {
                width: auto;
                margin: 0 auto;
            }
        }

        @include breakpoint(medium) {
            .rte {
                p {
                    color: $white-smoke;
                }
            }

            .message-section {
                padding: 0 0 50px;

                $iconHeadDimension: 188px;

                .icon-head {
                    height: $iconHeadDimension / 2;

                    > div {
                        width: $iconHeadDimension;
                        height: $iconHeadDimension;
                        top: -($iconHeadDimension / 2);

                        img {
                            //width: 100%;
                            border-radius: 100px;
                            width: auto;
                            height: auto;
                        }
                    }
                }

                .text {
                    width: 580px;
                    margin: 0 auto;
                }
            }
        }

        @include breakpoint(large) {
            .wrapper {
                min-height: 600px;
            }

            .rte {
                width: 75%;

                h2 {
                    font-size: 6.625rem;
                }
            }
        }

        @include breakpoint(xlarge) {
            .rte {
                width: 50%;
            }
        }

        .message-no-overlap {
            .icon-head {
                height: auto;

                > div {
                    position: relative;
                    top: auto;
                }
            }
        }
    }
}
