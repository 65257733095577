﻿.widget-offside-content {
    &.variant-simple {
        .title {
            text-align: center;
            margin-bottom: 4.6875rem;
        }

        .offside-contents {
            margin: 0 auto;

            .offside-content {
                display: flex;
                flex-flow: column nowrap;
                background-color: #EBEBEB;

                .image {
                    flex: 1;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 100%;
                    min-width: 150px;
                    height: 300px;
                    position: relative;
                }

                .text {
                    flex: 1;
                    align-self: center;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    width: 100%;


                    > div {
                        padding: 3rem 1rem;
                        flex-flow: column nowrap;
                        text-align: center;

                        a {
                        }
                    }
                }

                h3 {
                    margin-bottom: 20px;
                }

                p {
                    color: $matterhorn;
                }

                div.buttons {
                    .mdl-button {
                        font-size: rem-calc(18);
                        margin-top: 30px;
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
       

            @include breakpoint(medium) {
                .offside-content {

                    .image {
                        text-align: right;
                        width: 100%;
                    }

                    .text {
                        width: 100%;

                        > div {
                            //width: 480px;
                        }
                    }
                }
            }

            @include breakpoint(large) {
                .offside-content {
                    flex-flow: row nowrap;

                    .image {
                        flex: 1 1 0;
                        width: 50%;
                    }

                    .text {
                        flex: 1 1 0;
                        width: 50%;

                        > div {
                            width: 480px;
                            text-align: left;
                        }
                    }
                }

                div:nth-child(2n+0) {
                    .image {
                        order: 2;
                        text-align: left;
                        //min-height: 500px;
                    }

                    .text {
                        order: 1;
                    }
                }
            }

            @include breakpoint(xlarge) {
                .offside-content {
                    .image {
                        min-height: 500px;
                    }

                    .text {
                        width: auto;
                        min-height: 500px;

                        > div {
                            width: 600px;
                        }
                    }
                }
            }
        }
    }
}
