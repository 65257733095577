﻿.owl-nav {
    z-index: 1;

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &::before {
            font-family: "Hna";
            font-size: 3.5rem;
            text-shadow: 2px 2px 2px rgba(0,0,0,.22);
            opacity: 1;
            color: $white;
        }

        span {
            display: none;
        }

        &.owl-next {
            right: 20px;

            &::before {
                content: '\f008';
            }
        }

        &.owl-prev {
            left: 30px;

            &::before {
                content: '\f009';
            }
        }
    }
}

.widget-carousel {
    &.variant-images {
        .carousel-image-item {
            padding: 0 10px;

            img {
                border-radius: 3px;
            }
        }

        /*.instagram-carousel {
            .owl-item {
                display: inline-block;
                width: auto;
                white-space: nowrap;
            }
        }*/
    }

    &.variant-news {
        .news-item {
            display: flex;
            flex: 1 1 0;
            flex-flow: column nowrap;
            padding: 0 0.9375rem;
            height: 320px;

            > div {
                box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
                display: flex;
                flex-flow: column nowrap;

                > div.image-container {
                    width: 100%;
                    height: 190px;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                > div.text {
                    height: 120px;
                    padding: 20px;
                    display: flex;
                    flex-flow: column nowrap;
                }
            }

            img {
            }

            .category {
                font-size: rem-calc(12);
                letter-spacing: 3px;
                text-transform: uppercase;
                color: $black;
                margin-bottom: 0;
                flex-grow: 0;
            }

            h3 {
                @include font-calluna-regular;
                color: $deep-pink;
                font-size: rem-calc(20);
                line-height: 1;
                flex-grow: 1;
                margin-top: 10px;
                text-align: left;
            }

            .date {
                font-weight: $semibold;
                font-size: rem-calc(12);
                letter-spacing: 2px;
                text-transform: uppercase;
                color: $matterhorn;
                margin-bottom: 0;
                justify-self: flex-end;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
}