﻿.expander {
    position: relative;
    z-index: 1;
    text-align: right;
    display: inline-block;

    .trigger-container {
        position: relative;
        flex: 0 0 auto;
    }

    button {
        color: #fff;
        padding: 0 2rem;
        outline: 0;
        cursor: pointer;
    }

    .trigger {
        position: relative;
        z-index: 3;
        display: inline-block;

        &:hover {
            cursor: pointer;
        }
    }

    .content {
        width: 0;
        position: absolute;
        display: flex;
        align-items: center;
        overflow: hidden;
        z-index: 3;
        opacity: 0;
        justify-content: flex-end;

        input[type="text"] {
            border-bottom: none !important;
        }
    }

    .overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        width: 0;
        height: 0;

        div {
            position: absolute;
            transform: translate(0,0);
            width: 0;
            height: 0;
            position: relative;
            background-image: radial-gradient($blue, $blue calc(100% - 3rem), transparent);
        }
    }
}

.expander-container {
    overflow: hidden;
}
