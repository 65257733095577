﻿.widget-form-list {
    &.variant-default {
        .files-forms {
            width: 100%;

            .tabs {
                margin: 0 auto;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .tabs-content {
                background-color: $whisper;
                padding: 30px 0;
                margin-top: 30px;
            }

            .tabs-panel {
                width: 100%;
                margin: 0 auto;
            }

            .list {
                list-style: none;

                li {
                    display: block;
                    margin-top: 20px;

                    a {
                        font-size: rem-calc(18);
                        color: $deep-pink;

                        > .fa {
                            margin-right: 10px;
                        }
                    }
                }
            }

            @include breakpoint(medium) {
                .tabs {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                .tabs-content {
                    padding: 50px 0 100px;
                }
            }

            @include breakpoint(large) {
                .list {
                    columns: 2;
                    /*li {
                        width: 50%;
                        display: inline-block;
                    }*/
                }
            }
        }
    }
}
