﻿.widget-meet-the-team {
    &.variant-default {
        .meet-the-team {
            h3 {
                margin-bottom: 35px;
                text-align: center;
            }

            .members {
                display: flex;
                flex-flow: column nowrap;
            }

            .member {
                margin-bottom: 1rem;
                background-color: $whisper;
                box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
                flex: 0 0 50%;

                > div {
                    height: 100%;
                }

                p {
                    color: $black;
                }

                p.lable, p.data-text {
                    margin-bottom: 0;
                    font-size: rem-calc(12);
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }

                p.lable {
                    opacity: 0.38;
                    margin-top: 8px;
                }

                p.data-text {
                    margin-right: 0.5rem;

                    a {
                        color: $deep-pink;
                        text-transform: none;
                        word-break: break-all;
                    }

                    @include breakpoint(large) {
                        margin-right: 0;
                    }
                }

                p.educations {
                    text-transform: none;
                }
            }

            .member-profile {
                display: flex;
                height: 100%;

                > div:first-child {
                    display: flex;
                    position: relative;
                    flex: 0 0 50%;
                    height: 100%;

                    .arrow-right {
                        width: 0;
                        height: 0;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-right: 12px solid $whisper;
                        position: absolute;
                        top: 20px;
                        right: 0;
                        z-index: 2;
                    }
                }

                > div:nth-child(2) {
                    padding: 10px 0 10px 10px;
                    flex: 0 0 50%;
                }

                .member-img {
                    width: 105px;
                    height: 105px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                    z-index: 1;
                }

                .mobile-version {
                    display: flex;
                    flex-flow: column wrap;

                    .large-text {
                        margin-bottom: 0;
                    }

                    p.lable {
                        margin-top: 0;
                    }

                    p.data-text {
                        //word-break: break-word;
                    }
                }
            }

            .member-profile-mobile {
                padding: 0 10px 10px;
            }

            @include breakpoint(medium) {
                .members {
                    flex-flow: row wrap;
                }

                .member {
                    > div {
                        height: auto;
                    }
                }

                .member-profile {
                    flex: 1;

                    .member-img {
                        width: 150px;
                    }
                }
            }

            @include breakpoint(large) {
                .member {
                    > div {
                        height: 100%;
                    }
                }

                .member-profile {
                    flex: 1;

                    .member-img {
                        width: 200px;
                        height: 100%;
                    }
                }
            }

            @include breakpoint(xlarge) {
                h3 {
                    text-align: unset;
                }

                .member-profile {
                    > div:first-child {
                        flex: 0 1 auto;
                    }

                    > div:nth-child(2) {
                        padding: 20px 0 20px 20px;
                        flex: 0 1 auto;
                    }

                    .member-img {
                        width: 290px;
                        height: 100%;
                    }
                }
            }
        }
    }
}
