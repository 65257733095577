﻿html, body {
    overflow-x: hidden;
}

.body-container {
    overflow: hidden;
    padding-top: 70px;

    @include breakpoint(large) {
        padding-top: 120px;
    }
}

.grid-container {
    max-width: 75rem;

    &.wide {
        max-width: rem-calc(1400);
    }
}

.grid-x {
    margin-bottom: 0;

    .cell {
        margin-bottom: 0;
    }
}

.grid-row {
    z-index: 1;
    position: relative;
}

#content {
    
}