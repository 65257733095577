﻿@import '../_variables.scss';
@mixin colorButton($primary, $hover, $textColor: $white, $textColorHover: $white) {
    background-color: $primary;

    color: $textColor!important;

    &:hover {
        background-color: $hover;
        color: $textColorHover!important;
    }
}

.mdl-button {
    font-size: 1rem;
    @include colorButton($buttonColor, $buttonActiveColor);
    border-radius: $buttonRadius;
    color: #000;
    position: relative;
    height: 2.5em;
    margin: 0;
    min-width: 64px;
    padding: 0 2.5em .75em;
    display: inline-block;
    font-family: canada-type-gibson;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 2.5em;
    vertical-align: middle;

    &.default-size {
        min-width: 130px;
    }

    &.button-black {
        @include colorButton($buttonColorBlack, $buttonActiveColorBlack);
    }

    &.button-transparent {
        @include colorButton(transparent, $cannon-pink, $snow, $snow);
        border: 1px solid $snow;
    }

    &.button-gray {
        @include colorButton($snow, $cannon-pink, $deep-pink, $snow);
        border: 1px solid $snow;
    }

    &.button-darkgray {
        @include colorButton($gainsboro, $cannon-pink, $black, $snow);
        border: 1px solid $gainsboro;
    }

    &.button-transparent-red {
        @include colorButton(transparent, $cannon-pink, $deep-pink, $snow);
        border: 1px solid $deep-pink;
    }

    &.button-icon {
        @include colorButton(transparent, $cannon-pink, $deep-pink, $deep-pink);
        background-repeat: no-repeat;
        background-position: center center;
        font-size: rem-calc(18);
        padding: 15px 0;
        min-width: 0;
        height: 41px;
        width: 41px;
        border: 1px solid $deep-pink;
        border-radius: 41px;
        margin-left: 43px;


        img {
            width: 80%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.button-icon-long {
        @include colorButton(transparent, $cannon-pink, $white, $deep-pink);
        background-repeat: no-repeat;
        background-position: 20px center;
        font-size: rem-calc(12);
        padding: 15px;
        min-width: 250px;
        height: unset;
        border: 2px solid $white;
        letter-spacing: 3px;
        padding-left: 40px;
        line-height: 14px;
    }


    &.button-download {
        @include colorButton($deep-pink, $cannon-pink, $white, $white);
        font-size: rem-calc(12);
        padding: 16px 16px 16px 50px;
        min-width: 200px;
        letter-spacing: 3px;
        height: auto;
        font-weight: $regular;
        line-height: rem-calc(18);

        &:before {
            font-family: FontAwesome;
            content: "\f019";
            color: #fff;
            display: inline-block;
            padding-right: 3px;
            opacity: unset;
            font-size: 20px;
            position: absolute;
            top: 16px;
            left: 20px;
            right: unset;
        }
    }

    &.button-darkgray-rightarrow {
        &:before {
            font-family: FontAwesome;
            content: "\f105";
            color: $black;
            display: inline-block;
            padding-right: 3px;
            opacity: unset;
            font-size: rem-calc(40);
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }

        &:hover {
            &:before {
                color: $snow;
            }
        }
    }

    &.button-green {
        @include colorButton($half-baked, lighter($half-baked), $white, $white);
        font-size: rem-calc(12);
        min-width: 225px;
        padding: 8px;
        height: auto;
    }
}

.button-large {
    font-size: rem-calc(18);
    line-height: rem-calc(20);
    padding: 15px 40px;
    min-width: 275px;
    height: unset;
}

.button-small {
    font-size: rem-calc(12)!important;
    padding: 5px 0;
    min-width: 225px!important;
    height: unset;
}

/*page load popup start*/
.button-outline {
    background-color: transparent;
    color: #861F41 !important;
    border: 1px solid #861F41;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 5px 15px;
    font-weight: 400;
    margin-top: 15px;

    &:hover {
        background: #895061;
        color: #fff !important;
    }
}

.page-load-popup {
    padding: 30px 0;
}

@media (max-widht:575px) {
    .button-outline {
        font-size: 13px;
        height: 35px;
        padding: 5px 12px;
    }
}
/*page load popup end*/