﻿.widget-my-account {
    &.variant-default {
        .my-account-form {
            form {
                width: 100%
            }

            fieldset {
                margin-bottom: 30px;

                legend {
                    text-transform: uppercase
                }
            }
        }

        .button-placement {
            margin-top: 50px
        }
    }
}
