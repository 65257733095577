﻿.widget-map {
    &.variant-large {
        .map-container {
            //background-color: #f8f8f8;
            //height: $homepage-map-size;
            position: relative;
            background: #FFF;
            overflow: hidden;

            .map-bg {
                height: 200vh;
                top: 13%;
                left: 0;
            }

            .map {
                position: relative;
            }

            .hna-placed-logo {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover !important;
                position: fixed;
                height: 100%;
                width: 100%;
            }

            .map-wrapper {
                background: #fff;
                position: relative;
                top: 0;
                //height: $homepage-map-size;
                //width: calc(100%);
                background-size: cover;

                .text {
                    //position: absolute;
                }

                .large-text {
                    position: relative;
                }

                .desc {
                    text-align: center;
                    position: relative;
                    margin-top: 25px;

                    h2 {
                        color: #4F868E;
                        @include font-gibson-semibold;
                        font-size: rem-calc(150);
                        font-weight: $bold;
                        opacity: 0.46;
                        margin: 0;
                        text-align: center;
                        margin-bottom: 10px;
                    }

                    p {
                        //width: 450px;
                    }

                    .eyebrow {
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        font-size: $defaultFontBase;
                    }
                }
            }

            @include breakpoint(large) {
                background: #fff;
                overflow: hidden;
                height: 1000px; //default height

                .map-bg {
                    background-size: cover !important;
                    background-position: center center;
                    background-repeat: no-repeat;
                    position: fixed;
                    width: 100%;
                    opacity: 0;
                }

                .map-wrapper {
                    .text {
                        position: absolute;
                        right: 0;
                        //top: 120px;
                        width: 45%;
                        z-index: 10;
                    }

                    .desc {
                        left: 0;
                        width: 100%;
                        margin: 0 auto;

                        .title {
                            font-family: $body-font-family;
                            font-weight: 300;
                        }

                        .header-text {
                            top: 205px;
                            bottom: 100px;
                            position: absolute;
                            width: 100%;
                            text-align: center;
                            margin: 0;
                            opacity: 0;
                            transform: translateY(50px);

                            h3 {
                                font-size: rem-calc(68);
                                color: $night-rider;
                                @include font-calluna-semibold;
                                width: 100%;
                            }

                            .subtext {
                                font-size: rem-calc(20);
                                line-height: rem-calc(20);
                                max-width: 400px;
                                margin: 0 auto;
                                font-weight: 500;
                                color: #333;
                                display: block;
                                padding-top: 7px;
                                opacity: 0;
                                transform: translateY(50px);
                                @include font-gibson-regular;
                            }
                        }
                    }
                }

                .number-circle {
                    height: 151px;
                    width: 151px;
                    position: relative;
                    border: 1px solid #861F41;
                    display: block;
                    //justify-content: center;
                    //align-items: center;
                    border-radius: 50%;
                    margin: 0 auto;

                    img {
                        position: absolute;
                        top: 44px;
                        display: block;
                        left: 50%;
                        opacity: 0;
                        transform: translateY(50px) translateX(-50%);
                    }
                }
            }
        }
    }

    &.variant-small {
        display: flex;
        justify-content: center;

        .map-container {
            min-height: 500px;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;

            .map-bg {
                background-repeat: no-repeat;
                width: 100%;
                position: absolute;
                top: 10%;
                left: 0;
                transform: translateY(-50%);
                z-index: 1;
            }

            .text-wrap {
                display: flex;
            }

            .description-wrap {
                display: flex;
                flex-flow: column nowrap;
                flex: 0 0 33.33%;
                align-items: center;
            }

            .top-text {
                color: $gray;
                height: 30px;
            }

            .counter {
                font-size: rem-calc(70);
                color: #ADC7CB;
                font-weight: $semibold;
            }

            .header-text {
                text-align: center;

                h3 {
                    font-size: rem-calc(50);
                    color: $night-rider;
                    @include font-calluna-semibold;
                    width: 100%;
                }
            }
        }
    }
}
