﻿.widget-carousel-trumba-events {
    &.variant-default {
        a {
            display: block;
            padding: 10px;
        }

        .event-item {
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            background-color: $white;
            padding: 18px;
            border-radius: 5px;
            //width: 380px!important;
            margin-top: 10px;
            height: 290px;

            p {
                margin: 0;
            }

            p.event-title {
                font-size: 0.875rem;
                line-height: 1rem;
                color: $night-rider;
                font-weight: $regular;
                letter-spacing: 1.2;
                align-self: flex-start;
                text-align: left;
                text-transform: uppercase;
                margin: 10px 0;
            }

            > div {
                display: flex;
                flex-flow: column nowrap;
                height: 100%;
            }
        }

        .month-day {
            flex: 0 0 auto;
            height: 103px;
            background: $light-sea-green;
            border-radius: 4px;
            font-size: rem-calc(97);
            color: $white;
            text-align: center;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;


            .month {
                font-size: rem-calc(20);
                line-height: $line-height;
                font-weight: $semibold;
                color: $half-baked;
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            .day {
                font-size: rem-calc(40);
                line-height: $line-height;
                color: $white;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

        .desc {
            color: $black;
            display: flex;
            flex-flow: row wrap;
            flex: 1 100%;

            h3 {
                font-size: rem-calc(18);
                line-height: rem-calc(23);
                color: $night-rider;
                font-weight: $regular;
                letter-spacing: 1.2;
                align-self: flex-start;
                text-align: left;
            }

            > div {
                align-self: flex-end;
                width: 100%;
                text-transform: uppercase;
            }

            p {
                font-size: rem-calc(12);
                letter-spacing: 2px;
            }

            span {
                opacity: 0.38;
            }
        }

        @include breakpoint(large) {
            .event-item {
                width: 360px;
                height: 225px;

                > div {
                    display: flex;
                    flex-flow: row nowrap;
                    height: 100%;
                }

                p.event-title {
                    margin: 0;
                }
            }

            .month-day {
                width: 96px;
                margin-right: 10px;
            }

            .desc {
                flex: none;
            }

            .owl-nav {
                button {

                    &::before {
                        color: #333;
                    }

                    &:hover {
                        &::before {
                            color: $deep-pink;
                        }
                    }

                    &.owl-prev {
                        left: -75px;
                    }

                    &.owl-next {
                        right: -70px;
                    }
                }
            }
        }
    }
}
