﻿.tabs {
    border: none;

    .tabs-title {
        text-align: left;
        margin-right: 20px;
        min-height: 40px;
        margin-bottom: 10px;

        a {
            background: none;
            color: #474747;
            opacity: 0.5;
            text-transform: uppercase;
            padding: 10px 0;
            text-align: left;
            font-size: rem-calc(14);
            letter-spacing: 1.8px;
            line-height: rem-calc(16);
        }

        &.is-active {
            border-bottom: 3px solid $deep-pink;

            a {
                outline: none;
                opacity: 0.8;
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }

    @include breakpoint(large) {
        .tabs-title {
            margin-right: 40px;
        }
    }
}

.tabs-content {
    padding: 1rem 0;
    border: none;

    .tabs-panel {
        padding: 0;
    }
}
