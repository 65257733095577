﻿.parent-portal-wrapper {
    padding-right: 2rem;
    padding-left: 2rem;

    &.no-header-text {
        margin-top: 100px;
    }
}

.parent-portal-sidebar {
    h5 {
        color: darken($whisper, 30%);
        text-transform: uppercase;
        font-size: rem-calc(14);
        line-height: rem-calc(30);
        font-weight: $regular;
        letter-spacing: 3px;
        border-bottom: 1px solid darken($whisper, 30%);
    }

    ul {
        margin-left: 0;
        padding-top: 20px;

        li {
            list-style: none;
            padding-bottom: 20px;

            button {
                cursor: pointer;
            }

            a, button {
                font-size: rem-calc(18);
                color: darken($whisper, 45%);
                font-weight: $regular;

                &:hover {
                    color: $deep-pink;
                }
            }
        }
    }
}

.parent-registration-form {
    .button-placement {
        margin-top: 20px;
    }
}

#myaccount {
    h1 {
        margin-bottom: 50px;
        padding-left: 100px;
        text-align: left;
    }

    .my-account-form {
        margin-bottom: 100px;
        padding-left: 100px;

        form {
            width: 100%;
        }

        fieldset {
            margin-bottom: 30px;

            legend {
                text-transform: uppercase;
            }
        }
    }

    .button-placement {
        margin-top: 50px;
    }
}
