﻿.widget-content-header {
    &.variant-default {
        .message-section {
            position: relative;
            min-height: 200px;
            text-align: center;
            padding: 0 1rem 50px;

            $iconHeadDimension: 130px;

            .icon-head {
                height: $iconHeadDimension / 2;

                > div {
                    width: $iconHeadDimension;
                    height: $iconHeadDimension;
                    background-color: $white;
                    border-radius: 100px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -($iconHeadDimension / 2);
                    z-index: 2;

                    img {
                        border-radius: 75px;
                    }
                }
            }

            h3.no-image {
                margin-top: 70px;
            }

            .small-text, .small-text p, p {
                font-size: rem-calc(12);
                text-transform: uppercase;
                color: $black;
                letter-spacing: 2px;
                font-weight: 300;
            }

            .text {
                width: auto;
                margin: 0 auto;
            }
        }

        @include breakpoint(medium) {
            .message-section {
                padding: 0 0 50px;

                $iconHeadDimension: 188px;

                .icon-head {
                    height: $iconHeadDimension / 2;

                    > div {
                        width: $iconHeadDimension;
                        height: $iconHeadDimension;
                        top: -($iconHeadDimension / 2);

                        img {
                            //width: 100%;
                            border-radius: 100px;
                        }
                    }
                }

                .text {
                    width: 580px;
                    margin: 0 auto;
                }
            }
        }

        .message-no-overlap {
            .icon-head {
                height: auto;

                > div {
                    position: relative;
                    top: auto;
                }
            }
        }
    }
}
