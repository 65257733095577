﻿a {
    color: $linkColor;
    text-decoration: none;

    &.hover-line {
        position: relative;
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;

        &::after {
            position: absolute;
            content: '';
            height: 2px;
            background-color: $activeColor;
            width: 0;
            transition: all .3s;
            bottom: 0;
            left: 50%;
        }

        &.hover-line-top {
            &::after {
                bottom: auto;
                top: 0;
            }
        }

        &:hover, &.active {
            &::after {
                width: 100%;
                left: 0;
            }
        }
    }
}

.secondary-nav-link {
    display: inline-block;

    i, a {
        vertical-align: middle
    }

    a, a.hover-line {
        padding: 2px 0;
        //padding-left: 25px;
        height: auto;
    }

    i {
        color: $activeColor;
        margin: 0 .5em 0 0;
    }
}

.arrow-link {
    position: relative;
    padding-right: 2em;
    display: inline-block;
    &::after {
        font-family: BonsaiSeed;
        content: '\f013';
        font-size: 2em;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -.8em;
    }
    &:hover {
        text-decoration: underline;
    }
}

.link-transparent {

}