﻿.widget-video {
    &.variant-banner {
        .video-banner {
            position: relative;
            width: 100%;
            //padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }

        @include breakpoint(large down) {
            .video-banner {
                iframe {
                    height: 100%;
                }
            }
        }

        @include breakpoint(large) {
            .video-banner {
                &.full-height {
                    height: calc(100vh - 120px);
                }

                &.smaller {
                    padding-bottom: 36.5%;
                }
            }
        }

        @include breakpoint(xlarge) {
            .video-banner {
                height: 600px;
                padding-bottom: 0;

                iframe {
                    top: -50%;
                }

                video {
                    object-fit: cover;
                    object-position: top center;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
