﻿h1, h2, h3 {
    @include font-calluna-semibold;
    line-height: $line-height;
    margin: 0;
}

h4, h5, h6 {
    @include font-gibson-semibold;
    line-height: $line-height;
    margin: 0;
}

h1 {
    /*@include font-gibson-semibold;
    font-size: rem-calc(30);
    text-transform: uppercase;
    color: #7c7c7c;
    line-height: rem-calc(48);
    margin: 0;*/
    font-size: rem-calc(46);
    color: $night-rider;
    text-align: center;
}

h2 {
    font-size: rem-calc(46);
    color: $night-rider;
    text-align: center;
}

.title {
    @include font-calluna-regular;
    font-size: rem-calc(46);
    color: $night-rider;
    text-align: center;
}

.lead {
    font-size: rem-calc(21);
    text-align: center;
}

h3 {
    font-size: rem-calc(32);
    color: $deep-pink;
    margin-bottom: 10px;
}

h4 {
    font-size: rem-calc(30);
    text-transform: uppercase;
    color: #7c7c7c;
    line-height: rem-calc(48);
}

h5 {
    font-size: rem-calc(30);
    font-weight: $light;
    line-height: rem-calc(46);
}

p, li {
    @include font-gibson-regular;
    font-weight: $light;
}

p.large-text, span.large-text {
    font-size: rem-calc(21);
}

p.large-text-calluna, span.large-text {
    font-size: rem-calc(21);
    @include font-calluna-regular;
}

.eyebrow {
    font-size: rem-calc(12);
    text-transform: uppercase;
    line-height: 18px;
}

blockquote {
    border: none;
    @include font-gibson-light;
    font-style: italic;
    font-size: rem-calc(20);
    color: $night-rider;

    &:before {
        content: '\201C';
    }

    &:after {
        content: '\201D';
        margin-left: -0.005em;
    }
}

.grid-frame {
    font-size: $defaultFontBase;
}

p {
    margin-bottom: $defaultFontBase;
}

.inline-list {
    list-style-type: none;

    > li {
        display: inline-block;
        vertical-align: middle;
    }
}

.small-text {
    font-size: rem-calc(10);
}

.narrow-width {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.large-number {
    color: #4F868E;
    opacity: 0.46;
    font-size: rem-calc(98);
    font-weight: 700;
    line-height: 150px;
}

.small-title-bold {
    @include font-gibson-bold;
    font-size: rem-calc(10);
    text-transform: uppercase;
    font-weight: $semibold;
    letter-spacing: 3px;
}

.grey-text {
    color: $night-rider;
    opacity: 0.6;
}


b, strong {
    font-weight: $semibold;
}

.text-white {
    color: $white;

    h1, h2, h3, h4, h5, p {
        color: $white;
    }
}

.feed-title {
    color: $night-rider;
    font-size: rem-calc(23);
    font-weight: $light;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: 25px;
    width: 100%;
}

.clearfix {
    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


.nav-title {
    padding-left: 0;
    width: 87%;
    text-align: left;
    border-bottom: 1px solid #fff;
    line-height: 0.1em;
    margin: 10px 0 20px 1em;
    opacity: 0.5;

    span {
        color: $white;
        text-transform: uppercase;
        padding: 0 10px;
    }
}
