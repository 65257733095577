﻿@import '../_variables.scss';
@import '../_mixins.scss';
@import '_toggleIcon.scss';
@import '../mixins/_fonts.scss';

.header {
    font-size: $smallFontBase;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;

    a {
        text-transform: uppercase;
        font-family: $body-font-family;
        font-size: rem-calc(12);
        letter-spacing: 1pt;
        font-weight: normal;

        + a {
            margin-left: rem-calc(25);
        }

        @include breakpoint(large only) {
            + a {
                margin-left: rem-calc(10);
            }
        }

        @media (min-width:1200px) and (max-width: 1350px) {
            + a {
                margin-left: rem-calc(10);
            }
        }
    }

    .secondary-nav {
        height: 60px;
        background-color: $primaryColor;
        align-items: center;

        .grid-container {
            height: 100%;
        }

        a {
            color: $white;

            &.hover-line-top {
                padding: 6px 0;
            }
        }

        .secondary-nav-link {
            display: flex;
            align-items: center;
            padding: 0 0 0 3em;

            .hover-line {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }


        .grid-x {
            height: 100%;
            items: center;
            position: relative;

            > div {
                height: 100%;
            }

            > .cell:nth-child(2) {
                width: 194px;
            }
        }

        .social-media {
            a {
                margin-left: 5px;
                text-align: center;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .search-box {
            display: flex;
            position: relative;
            width: 100px;
        }

        .quick-links {
            display: flex;
            align-items: stretch;
            height: 100%;

            a {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                margin-right: 1rem;
            }

            a.iconed {
                width: 130px;
                margin-left: 0 !important;
                margin-right: 0;
                text-align: center;
                padding-top: 10px;
                justify-content: unset;

                &:nth-child(2) {
                    background: linear-gradient(144.84deg, #95DDD9 0%, #5CB8B2 100%);
                }

                &:nth-child(3) {
                    background: linear-gradient(143.47deg, #F6D500 0%, #EAAA00 100%);
                }

                &:nth-child(4) {
                    background: linear-gradient(148.71deg, #DCE125 0%, #B7BF10 100%);
                }

                img {
                    margin-bottom: 5px;
                    height: 17px;
                    width: 18px;
                    margin-left: auto;
                    margin-right: auto;
                }

                span {
                    letter-spacing: 2px;
                }

                @include breakpoint(large only) {
                    width: 100px;
                    padding-top: 5px;

                    span {
                        line-height: rem-calc(14);
                    }
                }
            }
        }

        .expander-container {
            .expander {
                height: 100%;

                .trigger-container {
                    position: relative;
                    height: 100%;

                    .trigger {
                        top: 19px;
                        position: absolute;
                        left: 0;
                    }

                    .overlay {
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-color: $deep-pink;
                    }
                }

                .content {
                    height: 40px;
                    top: 10px;
                    background: $white;
                    border-radius: 30px;
                    z-index: 2;

                    input {
                        width: 85%;
                        position: absolute;
                        right: 5px;
                        opacity: 0;
                        margin-bottom: 0;
                        border: 0;
                        padding-left: 0;
                        color: rgba(33,33,33,.6);
                        outline: 0 !important;
                        box-shadow: none !important;
                        background: 0 0
                    }
                }

                button {
                    color: $white;
                    padding: 0 2rem;
                    outline: none;
                    cursor: pointer;
                }

                &.open {
                    .content {
                        input {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @include breakpoint(large only) {
            .secondary-nav-link {
                padding: 0 0 0 1em;

                a {
                    padding-left: 0.1rem;
                    padding-right: 0.1rem
                }
            }
        }

        @media (min-width:1200px) and (max-width: 1350px) {
            .secondary-nav-link {
                padding: 0 0 0 1em;

                a {
                    padding-left: 0.1rem;
                    padding-right: 0.1rem
                }
            }
        }
    }

    .primary-nav {
        height: 60px;
        background: #fff;

        > div {
            height: 100%;
        }



        > .grid-container {
            > .grid-x {
                justify-content: space-between;
                height: 100%;
                position: relative;

                > .cell {
                    display: flex;
                    flex: 1;
                    padding: 0;
                    padding-left: 120px;
                    //justify-content: space-between;
                    align-items: center;
                    height: 100%;

                    &:first-child {
                        padding-right: 120px;
                        padding-left: 0;

                        > ul {
                            padding-left: 2em;
                        }
                    }

                    &:last-child {
                        > ul {
                            padding-right: 2em;
                        }
                    }
                }
            }
        }

        .dropdown.menu {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            position: static;

            li {
                list-style: none;
                height: 100%;
                position: static;

                > a {
                    height: 100%;
                    padding: 2em 2em;
                    color: $deep-pink;
                    //min-width: 100px;
                    text-align: center;
                    display: flex;
                    align-items: center;

                    &:hover, &.hover {
                        color: $white;
                        background-color: $siren;
                    }

                    &::after {
                        content: none;
                    }
                }
            }

            .dropdown-pane {
                background-color: $siren;
                border: none;
                width: 100%;
                margin: 0 auto !important;
                left: 0% !important;
                right: 0% !important;
                top: 60px !important;
                padding: 0;
                transition-duration: 0.2s;
                visibility: hidden;

                > li {
                    > .grid-x {
                        min-height: 455px;
                        width: 100%;

                        > .cell {
                            &:first-child {
                                min-height: 455px;
                                padding: 3rem 4em 1rem 0;
                            }
                        }
                    }
                }

                .nav-title {
                    width: 98%;

                    span {
                        background-color: $siren;
                        padding: 0 0.5em 0 1em;
                    }
                }

                .menu {
                    background: none;

                    a {
                        color: $white;
                        font-size: rem-calc(18);
                        text-align: left;
                        padding: 0.8em 2em;
                        text-transform: unset;

                        &:hover {
                            background-color: lighten($deep-pink, 10%);
                        }
                    }
                }

                &.js-dropdown-active {
                    visibility: visible;
                }
            }
        }

        .menu-banner {
            height: 100% !important;
            background-color: $siren;

            .banner-image {
                background-size: cover;
                height: 100%;
            }

            .banner-text {
                max-width: 400px;
                padding: 0;

                a {
                    background: #BFB8AF;
                    border-radius: 3px;
                    padding: 15px;
                    font-family: Gibson-Regular;
                    @include font-gibson-regular;
                    font-size: rem-calc(18);
                    color: #1E1E1E;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    text-align: center;
                    text-shadow: none;
                }
            }
        }

        .desktop-links, .mobile-controls {
            display: flex;
            //justify-content:flex-end;
        }

        .hover-line {
            height: 100%;
            display: flex;
            align-items: center;
        }
        //@include breakpoint(large only) {
        @media screen and (min-width: 1024px) and (max-width: 1440px) {
            .cell {
                > ul {
                    flex-wrap: nowrap;
                    padding-left: 0 !important;
                    padding-right: 0 !important;

                    li {
                        a {
                            min-width: unset;
                            font-size: rem-calc(11);
                            padding: 2em 1em;
                        }
                    }
                }
            }
        }
    }

    .toggle-button {
        font-size: 1.75em;
    }

    .social-media {
        @media screen and (min-width: 1024px) and (max-width:1450px) {
            display: none
        }
    }


    $logo-container-bg: #f9f9f9;

    .logo-container {
        align-items: flex-start;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 20;

        > div {
            background: $logo-container-bg;
            width: 194px;
            margin: 0 auto;
            box-shadow: 0 0 8px 0 rgba(0,0,0,0.17);
            height: 126px;
            position: relative;
            text-align: center;
            z-index: 9;
        }

        $border-width: calc(338.09px / 2);

        .arrow-down {
            width: 0px;
            height: 0px;
            border-left: $border-width solid transparent;
            border-right: $border-width solid transparent;
            border-top: 90px solid #fff;
            position: absolute;
            bottom: -90px;
        }

        .logo {
            z-index: 10;
            position: relative;
            margin: 0 auto;
            margin-top: 13px;
            width: 140px;
        }

        $arrow-size: 138.2px;

        .arrow {
            z-index: 9;
            background-color: $logo-container-bg;
            box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.17);
            position: absolute;
            bottom: -68px;
            left: 28px;
            width: $arrow-size;
            height: $arrow-size;
            /* Translate the box up by width / 2 then rotate */
            transform: scale(1, 0.5) rotate(-45deg);
        }
    }
}

.mobile-header {
    background-color: $deep-pink;
    height: 70px;

    .toggle-icon {
        margin-left: 5px;

        span {
            background: $white;
            opacity: 0.8;
        }
    }

    .toggle-text {
        color: $white;
        font-size: rem-calc(14);
        position: relative;
        top: -4px;
        opacity: 0.8;
        margin-left: 5px;
    }

    .grid-x {
        height: 100%;
    }

    .cell {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .logo {
        width: 64px;
        height: 64px;
        margin-top: 2px;
    }

    .phone {
        margin-bottom: 0;

        a {
            font-size: rem-calc(14);
            color: $white;
            opacity: 0.8;            
            padding-right: 10px;
        }
    }

    @include breakpoint(medium) {
        .toggle-text {
            font-size: rem-calc(18);
        }

        .phone {
            a {
                font-size: rem-calc(20);
            }
        }
    }
}


.header .footer-social-media ul.show-for-large {
    margin: 0;
}

.header .footer-social-media {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-social-icons {
    margin-top: 15px;
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .header .footer-social-media {
        display: none;
    }
}

@media (min-width:1199px) {
    .member-img.loaded {
        width: 350px !important;
        height: 350px !important;
    }
}

@media (max-width:767px) {
    #faculty .result-item .member > div:first-child div.member-img.loaded {
        display: block;
        height: 350px;
    }
}
