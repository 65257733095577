@import 'https://use.fortawesome.com/kits/93666cc6/publications/latest/woff2.css';
@import 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap';
@import 'vendors/_foundation.scss';

@import '_variables.scss';
@import '_mixins.scss';
@import 'mixins/_fonts.scss';
@import 'mixins/_buttons.scss';

@import 'common/_utils.scss';
@import 'common/_typography.scss';
@import 'common/_buttons.scss';
@import 'common/_links.scss';
@import 'common/_toggleIcon.scss';
@import 'common/_header.scss';
@import 'common/_headerMobile.scss';
@import 'common/_grid.scss';
@import 'common/_footer.scss';
@import 'common/_socialMedia.scss';
@import 'common/_tabs.scss';
@import 'common/_accordion.scss';
@import 'common/_forms.scss';
@import 'common/_pagination.scss';
@import 'common/_parentPortal.scss';
@import 'common/_reveal.scss';
@import 'common/_expander.scss';

.widget {
    z-index: 1;
    position: relative;
}

@import 'components/_banner.scss';
@import 'components/_offsideContent.scss';
@import 'components/_feed.scss';
@import 'components/_trumbaEventsFeed.scss';
@import 'components/_map.scss';
@import 'components/_iconLinks.scss';
@import 'components/_carousel.scss';
@import 'components/_contentHeader.scss';
@import 'components/_ctaButtons.scss';
@import 'components/_academicForms.scss';
@import 'components/_meetTheTeam.scss';
@import 'components/_courseList.scss';
@import 'components/_announcementBoard.scss';
@import 'components/_imageTextList.scss';
@import 'components/_classRepresentativeBatch.scss';
@import 'components/_imageLinks.scss';
@import 'components/_trumbaCalendarSpud.scss';
@import 'components/_carouselTrumbaEvents.scss';
@import 'components/_parentPortalLoginRegistration.scss';
@import 'components/_facultyStaffDirectory.scss';
@import 'components/_newsListing.scss';
@import 'components/_myAccount.scss';
@import 'components/_parentRegistrationForm.scss';
@import 'components/_search.scss';
@import 'components/_video.scss';