﻿.widget-search-results {
    &.variant-simple {
        h4 {
            margin-bottom: 2rem;
        }

        .result {
            a {
                display: block;
                margin-bottom: 1rem;
            }
        }
    }

    &.variant-standard {
        h4 {
            margin-bottom: 2rem;
        }

        .result {
            a {
                display: block;
                margin-bottom: 1rem;
            }
        }
    }
}
