﻿.widget-announcement-board {
    &.variant-default {
        .announcement-board {
            width: 100%;
            margin-top: 150px;
            padding-top: 120px;
            padding-bottom: 200px;
            background-color: $whisper;
            position: relative;
        }

        .cougar-logo {
            width: 195px;
            height: 195px;
            border-radius: 195px;
            position: absolute;
            top: -106px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            background: #fff;

            img {
                margin: 0 auto;
            }
        }

        .championship-board {
            margin-top: 50px;

            .padded {
                padding: 5px 5px 10px 5px;
            }

            .small-text,
            .footer-text {
                color: #4C4C4C;
                font-size: rem-calc(10);
                line-height: 16px;
                text-align: center;
                margin-bottom: 0;
                width: 100%;
            }

            .small-title-bold {
                @include font-gibson-bold;
                font-size: rem-calc(12);
                text-transform: uppercase;
                font-weight: $semibold;
                letter-spacing: 3px;
            }

            .summary {
                width: 100%;
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: $white;
                box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
                height: 100%;
                text-align: center;

                .eyebrow {
                    letter-spacing: 2.1px;
                }

                .large-number {
                    color: #4F868E;
                    opacity: 0.46;
                    font-size: rem-calc(250);
                    font-weight: $bold;
                    line-height: 150px;
                }

                .large-text {
                    color: #333333;
                    font-size: rem-calc(56);
                    @include font-calluna-semibold;
                    letter-spacing: -1.68px;
                    line-height: 67px;
                }
            }



            .list-details {
                text-align: center;
                display: flex;
                align-items: stretch;
                align-self: stretch;
                height: 100%;
                padding: 5px 5px 10px 0;
                margin-left: 0.3rem;
                margin-right: -0.3rem;

                > div {
                    display: flex;
                    padding-right: 5px;
                    width: 50%;
                }

                .details {
                    display: flex;
                    flex-flow: row wrap;
                    background-color: $white;
                    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
                    width: 100%;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    margin-bottom: 1rem;
                    height: 100%;
                    //justify-content: flex-end;
                    /*> * {
                flex: 0 0 auto;
            }*/
                    > div {
                        width: 100%;
                    }

                    > div:first-child {
                    }

                    > div:nth-child(2) {
                        align-self: flex-end;
                    }

                    h4 {
                        font-size: rem-calc(72);
                        font-weight: $bold;
                        color: $deep-pink;
                        opacity: 0.78;
                        margin-bottom: 15px;
                    }

                    ul {
                        list-style: none;
                        margin-left: 0;
                        margin-top: 25px;
                        margin-bottom: 50px;

                        li {
                            text-transform: uppercase;
                            font-style: italic;
                            font-size: rem-calc(12);
                            line-height: 1.75rem;
                            font-weight: $bold;
                            color: $night-rider;
                            opacity: 0.59;
                            letter-spacing: 2.1px;
                        }
                    }
                }
            }
        }

        @include breakpoint(large) {
            .championship-board {
                width: $container-width;
                margin: 0 auto;
                margin-top: 150px;

                .summary {
                    padding-top: 40px;
                    padding-bottom: 90px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .list-details {
                    margin-left: 0 !important;
                    margin-right: 0 !important;

                    > div {
                        flex: 1 1 0%;
                        width: auto;
                        flex-flow: row wrap;
                    }
                }
            }
        }

        .athletics-sports {
            margin-top: 40px;
            padding: 0 1rem;

            .accordion-title {
                padding: 1rem 0;
            }

            .accordion-content {
                padding: 0;
            }

            .athletics-sports-header {
                p {
                    font-size: rem-calc(16);
                    line-height: rem-calc(18);
                    text-transform: uppercase;
                    letter-spacing: 4px;
                    width: 100%;

                    strong {
                        font-weight: $semibold;
                    }
                }

                .title {
                    text-align: center;
                }

                .sports-teams {
                    text-align: center;
                }

                .sports-participants {
                    text-align: right;
                }
            }

            .row-item {
                margin-bottom: 10px;

                .cell {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 0;
                }

                .sports-name {
                    padding-left: 10px;
                }

                .sports-teams {
                    padding-left: 10px;
                    opacity: 0.5;
                }

                .sports-participants {
                    width: 100%;
                    text-align: right;
                }
            }

            .footer-text {
                padding: 0 10px;
                margin-top: 25px;
            }

            @include breakpoint(large) {
                width: 980px;
                margin: 0 auto;
                margin-top: 180px;

                .accordion-title {
                    padding: 1rem;
                }

                .accordion-content {
                    padding: 1rem;
                }


                .header {
                    p {
                        font-size: rem-calc(23);
                        line-height: rem-calc(25);
                    }

                    .title {
                        text-align: unset;
                    }

                    .sports-teams {
                        padding-left: 50px;
                        text-align: unset;
                    }

                    .sports-participants {
                        text-align: center;
                    }
                }

                .row-item {
                    margin-bottom: unset;

                    p {
                        margin-bottom: 16px;
                    }

                    .sports-name {
                        padding-left: 25px;
                    }

                    .sports-teams {
                        padding-left: 50px;
                        text-align: center;
                        opacity: 1;
                    }
                }
            }
        }


        .footer-content {
            .pad-top {
                padding-top: 4rem;
            }

            .narrow-width {
                padding-right: 20rem !important;
            }
        }
    }
}
