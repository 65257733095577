﻿.widget-parent-registration-form {
    &.variant-default {
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
        background: lighten($whisper, 5%);
        width: 100%;
        padding: 85px;
        text-align: center;
        padding: 4rem 2rem;

        @include breakpoint(medium) {
            padding: 4rem;
        }

        @include breakpoint(large) {
            padding: 4rem 10rem;
        }

        @include breakpoint(xlarge) {
            padding: 4rem 20rem;
        }

        form {
            margin-bottom: 50px;

            .mdl-textfield__label {
                color: darken($whisper, 40%);
            }

            .titleanddescription {
                margin-top: 2px;

                p {
                    color: $deep-pink;
                    font-size: rem-calc(10);
                    letter-spacing: 1px;
                    font-style: italic;
                    text-align: left;
                }
            }

            .umbraco-forms-navigation {
                text-align: center;

                input[type="submit"] {
                    @include colorButton($half-baked, lighter($half-baked), $white, $white);
                    font-size: rem-calc(12);
                    min-width: 225px;
                    padding: 8px;
                    height: auto;
                }
            }
        }
    }
}
