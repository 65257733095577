﻿.widget-class-representative-batch {
    &.variant-default {
        .class-representative {
            padding-top: 80px;
            padding-bottom: 105px;

            h3 {
                text-align: center;
                margin-bottom: 65px;
            }

            .tabs-content {
                background: $whisper;
                box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
                margin-top: 25px;
                padding: 20px;
            }

            .footer-text {
                margin-top: 25px;
            }

            @include breakpoint(medium) {
                .tabs-content {
                    padding: 20px 50px;
                }
            }

            @include breakpoint(large) {
                width: 780px;
                margin: 0 auto;

                .tabs-content {
                    padding: 70px 130px;
                }
            }
        }

        .class-representative-batch {
            clear: both;

            dt {
                clear: both;
                float: left;
                font-weight: $regular;
                width: 20%;
            }

            dd {
                float: left;
                //margin-left: 50px;
                width: 80%;
            }

            @include breakpoint(medium) {
                dt {
                    width: 10%;
                }

                dd {
                    width: 75%;
                }
            }
        }
    }
}
