@import '_variables.scss';

/*----------------------------
    1. Place holder
-----------*/

%clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

%cubic-transition-5s {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
%cubic-transition-7s {
    transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
%cubic-transition {
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
%cubic-transition-1-5 {
    transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

%transition {
    transition: all .5s linear;
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -ms-transition: all .5s linear;
    -o-transition: all .5s linear;
}
%transition-1250s {
    transition: all 1.25s linear;
    -webkit-transition: all 1.25s linear;
    -moz-transition: all 1.25s linear;
    -ms-transition: all 1.25s linear;
    -o-transition: all 1.25s linear;
}
%transition-2s {
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}
%transition-3s {
    transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
}
%transition-125s {
    transition: all 1.25s ease;
    -webkit-transition: all 1.25s ease;
    -moz-transition: all 1.25s ease;
    -ms-transition: all 1.25s ease;
    -o-transition: all 1.25s ease;
}
%appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

%absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

%border-none {
    border: none;
}

%gradient-overlay{
    
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.03+45,0.58+99 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.03) 45%, rgba(0,0,0,0.58) 99%, rgba(0,0,0,0.58) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.03) 45%,rgba(0,0,0,0.58) 99%,rgba(0,0,0,0.58) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.03) 45%,rgba(0,0,0,0.58) 99%,rgba(0,0,0,0.58) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#94000000',GradientType=0 ); /* IE6-9 */

}


%center-text {
    position: absolute;
    margin: auto;
    text-align: center;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

%heading-zero-margin {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
}

%box-reset-padding {
    &.npd {
        padding: 0;
    }
    &.npt {
        padding-top: 0;
    }
    &.npb {
        padding-bottom: 0;
    }
    &.npl {
        padding-left: 0;
    }
    &.npr {
        padding-right: 0;
    }
}

%box-reset-margin {
    &.nm {
        margin: 0;
    }
    &.nmt {
        margin-top: 0;
    }
    &.nmb {
        margin-bottom: 0;
    }
    &.nml {
        margin-left: 0;
    }
    &.nmr {
        margin-right: 0;
    }
}

%bg-colors {
    &.white {
        background: $white;
    }
    &.grey {
        background: $black;
    }
}
/*----------------------------
    2. Mixins
-----------*/

@mixin overlay($bg, $op, $z) {
    background: rgba($bg, $op);
    z-index: $z;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 100%;
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin height($height) {
    height: $height;
}

@mixin max-width($w) {
    max-width: $w;
}

@mixin min-width($min) {
    min-width: $min;
}

@mixin box-absolute($position, $left, $top, $right, $bottom, $margin) {
    position: $position;
    left: $left;
    right: $right;
    bottom: $bottom;
    top: $top;
    margin: $margin;
}

@mixin radius($r) {
    border-radius: $r;
}

@mixin min-width($wid) {
    min-width: $wid;
}

@mixin translate($value, $dir) {
    @if $dir==tansy {
        transform: translateY($value);
        -webkit-transform: translateY($value);
        -moz-transform: translateY($value);
        -ms-transform: translateY($value);
        -o-transform: translateY($value);
    }
    @else if $dir==tansx {
        transform: translateX($value);
        -webkit-transform: translateX($value);
        -moz-transform: translateX($value);
        -ms-transform: translateX($value);
        -o-transform: translateX($value);
    }
    @else if $dir==rot {
        transform: rotate($value);
        -webkit-transform: rotate($value);
        -moz-transform: rotate($value);
        -ms-transform: rotate($value);
        -o-transform: rotate($value);
    }
    @else if $dir==scle {
        transform: scale($value);
        -webkit-transform: scale($value);
        -moz-transform: scale($value);
        -ms-transform: scale($value);
        -o-transform: scale($value);
    }
    @else if $dir==skew {
        transform: skewX($value);
        -webkit-transform: skewX($value);
        -moz-transform: skewX($value);
        -ms-transform: skewX($value);
        -o-transform: skewX($value);
    }
}

@mixin grid($col, $mar, $flt) {
    float: $flt;
    margin-right: $mar;
    margin-bottom: $mar;
    width: ((100% - (($col - 1) * $mar)) / $col);
    &:nth-child(#{$col}n) {
        margin-right: 0;
    }
}

@mixin rowMachine($row, $margin) {
    width: ((100% - (($row - 1) * $margin)) / $row);
    &:nth-child(n) {
        margin-bottom: $margin;
        margin-right: $margin;
    }
    &:nth-child(#{$row}n) {
        margin-right: 0;
        margin-bottom: 0;
    }
}

@mixin line_btm($w, $h, $b, $a, $d) {
    content: '';
    width: $w;
    height: $h;
    background: $b;
    text-align: $a;
    display: $d;
}

/*
  Fonts 
*/

@mixin menu-links {
    color: $white;
    text-transform: uppercase;
    @include font-gibson-regular;
    font-size: rem-calc(12);
    letter-spacing: $letter-spacing;
}

/*
  4. Containers
*/
@mixin body-container-full {
    width: 100%;
    margin: 0 auto;
}

@mixin body-container {
    width: $container-width;
    margin: 0 auto;
}