﻿.widget-faculty-and-staff-directory {
    &.variant-default {
        .eyebrow {
            letter-spacing: 2.1px;
        }

        .directory-title {
            letter-spacing: 4px;
            line-height: 1.75rem;
            border-bottom: 6px solid $light-sea-green;
            text-transform: uppercase;
            padding-bottom: 5px;
        }



        .search-name {
            padding: 20px 15px 15px 10px;
            background: $whisper;
            position: relative;

            .fa-search {
                position: absolute;
                left: 10px;
                bottom: 22px;
                color: #979797;
            }

            .MuiTextField-root {
                margin-left: 30px;
                width: 85%;

                label {
                    font-style: italic;
                    font-family: $font-site;
                }
            }
        }

        .departments {
            margin-top: 50px;

            ul {
                list-style: none;
                margin-left: 0;

                li {
                    float: left;
                    width: 50%;
                }
            }

            .MuiFormControlLabel-label {
                font-size: 0.875rem;
            }
        }

        .filters {
            background: $whisper;
            padding: 10px 20px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            font-size: rem-calc(13);

            p {
                margin: 0;
                flex: 1;
                color: #333;
                font-style: italic;
            }

            a {
                justify-self: end;
                font-style: italic;
                color: #333;
                text-decoration: underline;

                &:before {
                    content: '\00d7';
                    font-style: normal;
                    font-size: rem-calc(11);
                }
            }
        }

        .directory-wrapper {
            margin-top: 2.125rem;
        }

        .member {
            margin-top: 1.25rem;
            background-color: $whisper;
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            flex: 0 0 50%;

            > div {
                height: 100%;
            }

            p {
                color: $black;
            }

            p.lable, p.data-text {
                margin-bottom: 0;
                font-size: rem-calc(12);
                letter-spacing: 2px;
                text-transform: uppercase;
            }

            p.lable {
                opacity: 0.38;
                margin-top: 8px;
            }

            p.data-text {
                margin-right: 0.5rem;

                a {
                    color: $deep-pink;
                    text-transform: none;
                    word-break: break-all;
                }

                @include breakpoint(large) {
                    margin-right: 0;
                }
            }

            p.educations {
                text-transform: none;
            }
        }

        .member-profile {
            display: flex;
            height: 100%;

            > div:first-child {
                display: flex;
                position: relative;
                flex: 0 0 50%;

                .arrow-right {
                    width: 0;
                    height: 0;
                    border-top: 28px solid transparent;
                    border-bottom: 28px solid transparent;
                    border-right: 20px solid $whisper;
                    position: absolute;
                    top: 35px;
                    right: 0;
                    z-index: 2;
                    display: none;
                }
            }

            > div:nth-child(2) {
                padding: 10px 0 10px 10px;
                flex: 0 1 50%;
            }

            .member-img {
                width: 105px;
                height: 105px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                z-index: 1;
            }

            .mobile-version {
                display: flex;
                flex-flow: column wrap;

                .large-text {
                    margin-bottom: 0;
                }

                p.lable {
                    margin-top: 0;
                }

                p.data-text {
                    //word-break: break-word;
                }
            }
        }

        .member-profile-mobile {
            padding: 0 10px 10px;
        }

        .pagination {
            margin-top: 1.875rem;
            text-align: center;
            display: block;
            padding: 0 0.8rem;
        }

        @include breakpoint(medium) {
            .member-profile {
                flex: 1;

                .member-img {
                    width: 150px;
                }
            }
        }

        @include breakpoint(large) {
            .faculty-staff-wrapper {
                > div {
                    &:first-child {
                        padding-right: 1rem;
                    }

                    &:nth-child(2) {
                        padding-left: 1rem;
                    }
                }
            }

            .departments {
                ul {
                    li {
                        margin-bottom: 15px;
                    }
                }
            }

            .member-profile {
                flex: 1;

                .member-img {
                    width: 200px;
                    height: 100%;
                }
            }
        }

        @include breakpoint(xlarge) {
            h3 {
                text-align: unset;
            }

            .member-profile {
                > div:first-child {
                    flex: 0 0 auto;

                    .arrow-right {
                        display: block;
                    }
                }

                > div:nth-child(2) {
                    padding: 20px 0 20px 20px;
                    flex: 0 1 auto;
                }

                .member-img {
                    width: 305px;
                    height: 100%;
                }
            }
        }
    }
}
