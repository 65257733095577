﻿.widget-parent-portal-login-and-registration {
    &.variant-default {
        .login-form {
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            background: $white;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 75px;
            margin-bottom: 115px;

            @include breakpoint(large) {
                height: 500px;
            }

            @include breakpoint(xlarge) {
                @include body-container;
                margin-top: 75px;
                margin-bottom: 115px;
            }

            > .grid-x {
                height: 100%;

                > .cell {
                    display: flex;
                    flex-flow: column nowrap;
                    height: 100%;
                    justify-content: center;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    padding-top: 2rem;
                    padding-bottom: 2rem;

                    @include breakpoint(medium) {
                        padding-left: 4rem;
                        padding-right: 4rem;
                    }

                    @include breakpoint(large) {
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    &:last-child {
                        background-color: #EBEBEB;
                    }
                }
            }

            .umbraco-forms-navigation {
                text-align: center;
                padding-top: 50px;

                input[type="submit"] {
                    min-width: 225px;
                    padding: 8px;
                }
            }

            .button-placement {
                margin-top: 10px;

                a {
                    text-decoration: underline;
                }

                button {
                    margin-top: 10px;
                }
            }
        }
    }
}
