﻿@import url("https://use.typekit.net/bhr3uts.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@import '../_variables.scss';
@mixin font-gibson {
    font-family: $font-site;
    font-style: normal;
}

@mixin font-gibson-light {
    @include font-gibson;
    font-weight: $light;
}

@mixin font-gibson-regular {
    @include font-gibson;
    font-weight: $regular;
}

@mixin font-gibson-bold {
    @include font-gibson;
    font-weight: $bold;
}

@mixin font-gibson-semibold {
    @include font-gibson;
    font-weight: $semibold;
}

@mixin font-calluna {
    font-family: $font-calluna;
    font-style: normal;
}

@mixin font-calluna-light {
    @include font-calluna;
    font-weight: $light;
}

@mixin font-calluna-regular {
    @include font-calluna;
    font-weight: $regular;
}

@mixin font-calluna-semibold {
    @include font-calluna;
    font-weight: $semibold;
}

@mixin font-futura {
    font-family: $font-futura;
    font-style: normal;
}

@mixin font-futura-regular {
    @include font-futura;
    font-weight: $regular;
}