﻿.widget-icon-links {
    &.variant-social-media {
        .icon-links {
            display: flex;
            justify-content: center;

            a {
                @include colorButton(transparent, $cannon-pink, $gray, $white);
                background-repeat: no-repeat;
                background-position: center center;
                font-size: rem-calc(18);
                padding: 15px 0;
                display: flex;
                height: 41px;
                width: 41px;
                border: 1px solid $deep-pink;
                border-radius: 41px;
                margin-left: 43px;
                align-items: center;
                justify-content: center;


                .fa {
                    color: inherit;
                    font-size: 1.25rem;
                }

                &:hover {
                    .fa {
                        color: inherit;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
