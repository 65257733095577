﻿.pagination {
    text-align: center;
    display: block;
    padding: 0 .8rem;

    ul {
        list-style-type: none;
        margin: 0;
    }

    a, ul {
        display: inline-block;
        vertical-align: middle;
    }

    li {
        display: inline-block;
    }

    a {
        display: inline-block;
        vertical-align: middle;
        color: #6d6e71;
        margin: 0 0.8rem;
        font-size: 1rem;
        font-weight: 400;

        &.active {
            color: #FFF;
            position: relative;

            span {
                z-index: 2;
                position: relative;
            }

            &::after {
                position: absolute;
                z-index: 1;
                background-color: $deep-pink;
                content: '';
                width: 2rem;
                height: 2rem;
                border-radius: 1rem;
                left: 50%;
                top: 50%;
                margin-left: -1rem;
                margin-top: -1rem;
            }
        }
    }
}
