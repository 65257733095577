﻿.widget-cta-buttons {
    &.variant-default {
        .cta-buttons {
            a {
                display: inline-block;
                margin-bottom: 1rem;
                width: 275px;
            }
        }

        @include breakpoint(medium) {
            .cta-buttons {
                a {
                    margin-left: 1rem;
                    width: auto;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        @include breakpoint(large) {
            .cta-buttons {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }
}
