﻿
$sides: 'top', 'right', 'bottom', 'left';

@each $breakpoint in $breakpoint-classes {
    @include breakpoint($breakpoint) {
        @each $side in $sides {
            @each $size, $padding in $paddings {
                .#{$breakpoint}-padding-#{$side}-#{$size} {
                    padding-#{$side}: $padding;
                }
            }

            @each $size, $margin in $margins {
                .#{$breakpoint}-margin-#{$side}-#{$size} {
                    margin-#{$side}: $margin;
                }
            }
        }

        $gutter: rem-calc(map-get($grid-margin-gutters, $breakpoint));

        @if ($gutter != null) {
            .grid-x {
                margin-bottom: -$gutter;

                .cell {
                    margin-bottom: $gutter;
                }
            }
        }
    }
}

