﻿.widget-course-list {
    &.variant-default {
        .course-list {
            text-align: center;
            text-transform: uppercase;
            padding-top: 30px;
            padding-bottom: 50px;
            padding-left: 1rem;
            padding-right: 1rem;
            width: 100%;

            .small-text {
                font-size: rem-calc(12);
            }

            .title {
                text-transform: none;
            }

            .list {
                margin-top: 65px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
            }

            h2.title {
                margin-bottom: 25px;
            }

            @include breakpoint(medium) {
                .list {
                    margin-left: 1rem;
                    margin-right: 1rem;
                    flex-flow: row wrap;
                }
            }

            @include breakpoint(large) {
                margin: 0 auto;
                padding-top: 65px;
                padding-bottom: 100px;
                padding-left: 0;
                padding-right: 0;

                .list {
                    flex-flow: row wrap;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }

        .course-item {
            background-color: $white;
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
            padding: 20px 10px 20px 10px;
            margin-bottom: 1rem;

            > div {
                display: flex;
            }

            h3 {
                color: $cannon-pink;
                @include font-gibson-semibold;
                font-size: rem-calc(30);
                margin-bottom: 8px;
                margin-right: 10px;
            }

            h4 {
                font-size: rem-calc(12);
                letter-spacing: 2px;
                font-weight: $regular;
                line-height: rem-calc(14);
            }

            .subjects {
                list-style: none;
                margin-top: 20px;
                margin-left: 0;

                li {
                    p {
                        font-size: rem-calc(12);
                        margin-bottom: 20px;
                    }

                    .separator {
                        display: none;
                    }
                }
            }

            @include breakpoint(medium) {
                flex: 0 0 48.5%;
            }


            @include breakpoint(large) {
                padding: 28px 19px 80px 19px;
                flex: 0 0 45%;
                margin-bottom: 100px;

                > div {
                    display: block;
                }

                h3 {
                    @include font-gibson-semibold;
                    font-size: rem-calc(72);
                    margin-bottom: 8px;
                    margin-right: 0;
                }

                h4 {
                    font-size: rem-calc(23);
                    letter-spacing: 4px;
                    font-weight: $regular;
                }

                .subjects {
                    margin-top: 60px;

                    li {
                        p {
                            margin-bottom: 0;
                        }

                        .separator {
                            color: $gainsboro;
                            margin: 20px 0;
                            display: block;
                        }

                        &:first-child {
                            .separator {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
